// export const endPoints = {
//   ip: '10.211.2.15',
//   port: ':3352',
//   contextPath: '/api/1.1',
// };

// export const environment = {
//   production: false,
//   baseUrl: `http://${endPoints.ip}${endPoints.port}${endPoints.contextPath}`,
// };

export const endPoints = {
    ip: 'einv-tgm-tpm.com',
    port: ':3352',
    contextPath: '/api/1.1',
   };
   export const environment = {
    production: false,
    baseUrl: `https://${endPoints.ip}${endPoints.contextPath}`,
   };